<template>
  <div></div>
</template>

<script>
export default {
    mounted(){
        let params = this.$route.query
        this.$router.replace({name:'ReturnWork',query:params})
    }
}
</script>

<style>

</style>